let routes = [
	{
		id: '1',
		url: '/',
		icon: 'dashboard',
		title: 'dashboard.title',
		roles: ['root'],
	},
	{
		id: '2',
		url: '/users',
		icon: 'person',
		title: 'users.title',
		roles: ['root'],
	},
	{
		id: '3',
		url: '/logs',
		icon: 'format_list_numbered_rtl',
		title: 'logs.title',
		roles: ['root'],
	},
	{
		id: '4',
		url: '/portability',
		icon: 'sim_card',
		title: 'portability.title',
		roles: ['root'],
	},
	{
		id: '5',
		url: '/ratings',
		icon: 'thumb_up',
		title: 'ratings.title',
		roles: ['root'],
	},
	{
		id: '6',
		url: '/ratings-logs',
		icon: 'rule',
		title: 'ratingLogs.title',
		roles: ['root'],
	},
];
export default routes;
