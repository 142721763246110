import { useContext } from 'react';
import { useIntl } from 'react-intl';
import DataTable from 'common/dataTable';
import { GlobalsContext } from 'context/globals';
import { useDataTable } from 'hooks/useDataTableDigitalk';
import { getNonEmptyFieldsRatings } from 'utils/formatData';
import DownloadToCsv from 'common/downloadToCsv';
const RatingsTable = () => {
	const { state } = useContext(GlobalsContext);
	const { formatMessage } = useIntl();

	const { isLoading, response } = useDataTable(
		'ratings',
		getNonEmptyFieldsRatings(state.rating),
		state.reload
	);
	const columns = [
		{
			name: formatMessage({ id: 'table.id' }),
			selector: (row: any) => row.Id,
			sortable: true,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.npRoutingPlanId' }),
			selector: (row: any) => row.NpRoutingPlanId,
			sortable: true,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.operatorCode' }),
			selector: (row: any) => row.OperatorCode,
			sortable: true,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.diallingCode' }),
			selector: (row: any) => row.DiallingCode || '-',
			sortable: true,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.description' }),
			selector: (row: any) => row.Description || '-',
			sortable: true,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.ratingCode' }),
			selector: (row: any) => row.RatingCode || '-',
			sortable: true,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.customerRating' }),
			selector: (row: any) => row.CustomerRating.toString() || '-',
			sortable: true,
			wrap: true,
		},
	];
	return (
		<div>
			<DataTable
				data={response}
				columns={columns}
				progressPending={isLoading}
				title="Digitakl"
				paginationServer
			/>
			<DownloadToCsv
				data={response}
				filename="ratings-codes-query"
			/>
		</div>
	);
};

export default RatingsTable;
