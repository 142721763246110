import { MainTitle } from 'common/text';
import { Content } from 'common/containers';
import RatingLogsTable from 'components/tables/ratingLogs';
const RatingLogs = () => {
	return (
		<>
			<MainTitle>
				<h1>Operator rating code Logs</h1>
			</MainTitle>
			<Content $borderRadius=" 0 .75rem  .75rem .75rem">
				<RatingLogsTable />
			</Content>
		</>
	);
};
export default RatingLogs;
