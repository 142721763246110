import ButtonIcon from 'common/buttons/buttonIcon';
import { Flex } from 'common/containers';
import { useIntl } from 'react-intl';
import { downloadToCsvAction } from 'utils/downloadToCsv';
interface DownloadToCsvProps {
	data: any;
	filename: string;
}
const DownloadToCsv = (props: DownloadToCsvProps) => {
	const { data, filename } = props;
	const { formatMessage } = useIntl();
	return (
		<Flex
			$h="flex-end"
			$margin="3rem 0 0 0">
			<ButtonIcon
				$position="right"
				$icon="download"
				$text={formatMessage({ id: 'commons.download' })}
				onClick={() => downloadToCsvAction(data, filename)}
			/>
		</Flex>
	);
};
export default DownloadToCsv;
