import { useContext } from 'react';
import { useFormik, FormikProvider } from 'formik';
import { Grid, Flex } from 'common/containers';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { GlobalsContext } from 'context/globals';
// inputs
import Input from 'components/forms/inputs/input';
import Submit from 'components/forms/inputs/submit';
// utils
import useManageForms from 'hooks/useManageForms';
const AddPortabilityForm = () => {
	const { formatMessage } = useIntl();
	const { dispatch } = useContext(GlobalsContext);
	const { submit } = useManageForms();
	const formik = useFormik({
		initialValues: {
			ddi: '',
			exchangeCode: '',
			operatorCode: '',
			serviceCode: '',
		},
		validationSchema: Yup.object({
			ddi: Yup.string()
				.required('Este campo es obligatorio')
				.min(8, 'Mínimo 8 digitos')
				.max(12, 'Máximo 12 digitos'),
			exchangeCode: Yup.string().required('Este campo es obligatorio'),
			operatorCode: Yup.string().required('Este campo es obligatorio'),
			serviceCode: Yup.string().required('Este campo es obligatorio'),
		}),
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			await submit(values, '/portability', 'Número agregado correctamente', '/');
			dispatch({ ...values, reload: Math.random() });
			formik.resetForm();
			setSubmitting(false);
		},
	});
	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				<Grid $columns={2}>
					<Input
						nameData="ddi"
						placeholderData={formatMessage({ id: 'table.ddi' })}
						typeData="text"
						label={formatMessage({ id: 'table.ddi' })}
					/>
					<Input
						nameData="operatorCode"
						placeholderData={formatMessage({ id: 'table.operatorCode' })}
						typeData="text"
						label={formatMessage({ id: 'table.operatorCode' })}
					/>
					<Input
						nameData="exchangeCode"
						placeholderData={formatMessage({ id: 'table.exchangeCode' })}
						typeData="text"
						label={formatMessage({ id: 'table.exchangeCode' })}
					/>
					<Input
						nameData="serviceCode"
						placeholderData={formatMessage({ id: 'table.serviceCode' })}
						typeData="text"
						label={formatMessage({ id: 'table.serviceCode' })}
					/>
				</Grid>
				<Flex $margin="2rem 0 0 0">
					<Submit
						onClick={formik.handleSubmit}
						disabled={formik.isSubmitting}>
						{formatMessage({ id: 'commons.add' })}
					</Submit>
				</Flex>
			</form>
		</FormikProvider>
	);
};
export default AddPortabilityForm;
