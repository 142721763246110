import { useContext } from 'react';
import { MainTitle, Subtitle } from 'common/text';
import { Content, Grid } from 'common/containers';
import RatingsTable from 'components/tables/ratings';
import RatingSearch from 'components/forms/ratingSearch';
import { GlobalsContext } from 'context/globals';
import AddRatings from 'components/ratings/add';
import DeleteRatings from 'components/ratings/delete';
const Ratings = () => {
	const { state } = useContext(GlobalsContext);
	return (
		<>
			<MainTitle>
				<h1>Operator Ratings codes - Digitalk</h1>
			</MainTitle>
			<Content>
				<Subtitle $margin="0 0 1rem 0">Búsqueda de registros en ratings Digitalk:</Subtitle>
				<RatingSearch />
				{(state?.rating?.npRoutingPlanId ||
					state?.rating?.operatorCode ||
					state?.rating?.diallingCode ||
					state?.rating?.description ||
					state?.rating?.ratingCode ||
					state?.rating?.customerRating) && <RatingsTable />}
			</Content>
			<Grid
				$columns={2}
				$separator="2rem">
				<AddRatings />
				<DeleteRatings />
			</Grid>
		</>
	);
};
export default Ratings;
