import styled from 'styled-components';
interface ButtonIconProps {
	$text: string;
	$icon: string;
	$position?: string;
	onClick?: any;
	$tooltip?: string;
}
export const MyButton = styled.button<ButtonIconProps>`
	width: 180px;
	line-height: 1.5;
	font-weight: 700;
	letter-spacing: 0.05rem;
	font-size: 12px;
	padding: 0.5rem 0;
	background-color: ${props => props.theme.primaryColor};
	color: #fff;
	border-radius: 5px;
	transition: all 0.2s ease-in;
	border: none;
	cursor: pointer;
	position: relative;
	&:disabled {
		opacity: 0.5;
	}
	div {
		transition: 0.5s;
		pointer-events: none;
	}
	&:hover {
		color: #fff;
		div {
			visibility: visible;
			opacity: 1;
		}
		&.left span {
			padding-left: 25px;
			&::before {
				opacity: 1;
				left: 0;
			}
		}
		&.right span {
			padding-right: 25px;
			&:after {
				opacity: 1;
				right: 0;
			}
		}
	}
	span {
		cursor: pointer;
		display: inline-block;
		position: relative;
		transition: 0.5s;
	}
	&.left span::before {
		font-family: 'Material Icons';
		content: '${props => props.$icon}';
		font-size: 1rem;
		-webkit-font-feature-settings: 'liga' 1;
		-moz-font-feature-settings: 'liga' 1;
		font-feature-settings: 'liga' 1;
		position: absolute;
		opacity: 0;
		top: 0;
		line-height: 1rem;
		left: -20px;
		transition: 0.5s;
		color: #fff;
	}
	&.right span:after {
		font-family: 'Material Icons';
		content: '${props => props.$icon}';
		font-size: 1rem;
		-webkit-font-feature-settings: 'liga' 1;
		-moz-font-feature-settings: 'liga' 1;
		font-feature-settings: 'liga' 1;
		position: absolute;
		opacity: 0;
		top: 0;
		line-height: 1rem;
		right: -20px;
		transition: 0.5s;
		color: #fff;
	}
	/* &.left {
		&:hover {
			color: #fff;
			div {
				opacity: 1;
			}
			span {
				padding-left: 25px;
				&::before {
					opacity: 1;
					left: 0;
				}
			}
		}
		span {
			cursor: pointer;
			display: inline-block;
			position: relative;
			transition: 0.5s;
			&::before {
				font-family: 'Material Icons';
				content: '${props => props.$icon}';
				font-size: 1rem;
				-webkit-font-feature-settings: 'liga' 1;
				-moz-font-feature-settings: 'liga' 1;
				font-feature-settings: 'liga' 1;
				position: absolute;
				opacity: 0;
				top: 0;
				line-height: 1rem;
				left: -20px;
				transition: 0.5s;
				color: #fff;
			}
		}
	}
	&.right {
		&:hover {
			color: #fff;
			div {
				opacity: 1;
			}
			span {
				padding-right: 25px;
				&:after {
					opacity: 1;
					right: 0;
				}
			}
		}
		span {
			cursor: pointer;
			display: inline-block;
			position: relative;
			transition: 0.5s;
			&:after {
				font-family: 'Material Icons';
				content: '${props => props.$icon}';
				font-size: 1rem;
				-webkit-font-feature-settings: 'liga' 1;
				-moz-font-feature-settings: 'liga' 1;
				font-feature-settings: 'liga' 1;
				position: absolute;
				opacity: 0;
				top: 0;
				line-height: 1rem;
				right: -20px;
				transition: 0.5s;
				color: #fff;
			}
		}
	} */
`;
export const Tooltip = styled.div`
	position: absolute;
	padding: 10px;
	border-radius: 5px;
	min-width: 200px;
	top: -16px;
	left: 50%;
	transform: translate(-50%, -103%);
	background-color: ${props => props.theme.body};
	border: 1px solid ${props => props.theme.bordercolor};
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
	z-index: 9998 !important;
	font-size: 12px;
	opacity: 0;
	font-weight: bolder;
	color: #000;
	visibility: hidden;
	&::after {
		content: '';
		position: absolute;
		bottom: -10px;
		left: 50%;
		margin-left: -9px;
		width: 18px;
		height: 18px;
		background-color: ${props => props.theme.body};
		transform: rotate(45deg);
		border-bottom: 1px solid ${props => props.theme.bordercolor};
		border-right: 1px solid ${props => props.theme.bordercolor};
	}
`;
