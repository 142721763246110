import moment from 'moment';
export const downloadToCsvAction = (data: any, filename: string) => {
	const csv = data
		.map((row: { [s: string]: unknown } | ArrayLike<unknown>) => Object.values(row).join(','))
		.join('\n');
	const blob = new Blob([csv], { type: 'text/csv' });
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.setAttribute('hidden', '');
	a.setAttribute('href', url);
	a.setAttribute('download', `${filename}-${moment().format('DD-MM-YY-HH-mm-ss')}.csv`);
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
};
