import { useContext } from 'react';
import { useFormik, FormikProvider } from 'formik';
import { Grid, Flex } from 'common/containers';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { GlobalsContext } from 'context/globals';
// inputs
import Input from 'components/forms/inputs/input';
import Submit from 'components/forms/inputs/submit';

// utils
const AddServerForm = () => {
	const { formatMessage } = useIntl();
	const { dispatch } = useContext(GlobalsContext);
	const formik = useFormik({
		initialValues: {
			npRoutingPlanId: '',
			operatorCode: '',
			diallingCode: '',
			description: '',
			ratingCode: '',
			customerRating: '',
		},
		validationSchema: Yup.object({
			npRoutingPlanId: Yup.string().test(
				'atLeastOne',
				'*Al menos debes llenar un campo',
				(value, context) => {
					const { parent } = context;
					return !!(
						value ||
						parent.operatorCode ||
						parent.diallingCode ||
						parent.description ||
						parent.ratingCode ||
						parent.customerRating
					);
				}
			),
			operatorCode: Yup.string().test(
				'atLeastOne',
				'*Al menos debes llenar un campo',
				(value, context) => {
					const { parent } = context;
					return !!(
						value ||
						parent.npRoutingPlanId ||
						parent.diallingCode ||
						parent.description ||
						parent.ratingCode ||
						parent.customerRating
					);
				}
			),
			diallingCode: Yup.string().test(
				'atLeastOne',
				'*Al menos debes llenar un campo',
				(value, context) => {
					const { parent } = context;
					return !!(
						value ||
						parent.npRoutingPlanId ||
						parent.operatorCode ||
						parent.description ||
						parent.ratingCode ||
						parent.customerRating
					);
				}
			),
			description: Yup.string().test(
				'atLeastOne',
				'*Al menos debes llenar un campo',
				(value, context) => {
					const { parent } = context;
					return !!(
						value ||
						parent.npRoutingPlanId ||
						parent.operatorCode ||
						parent.diallingCode ||
						parent.ratingCode ||
						parent.customerRating
					);
				}
			),
			ratingCode: Yup.string().test(
				'atLeastOne',
				'*Al menos debes llenar un campo',
				(value, context) => {
					const { parent } = context;
					return !!(
						value ||
						parent.npRoutingPlanId ||
						parent.operatorCode ||
						parent.diallingCode ||
						parent.description ||
						parent.customerRating
					);
				}
			),
			customerRating: Yup.string().test(
				'atLeastOne',
				'*Al menos debes llenar un campo',
				(value, context) => {
					const { parent } = context;
					return !!(
						value ||
						parent.npRoutingPlanId ||
						parent.operatorCode ||
						parent.diallingCode ||
						parent.description ||
						parent.ratingCode
					);
				}
			),
		}),
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			dispatch({ rating: { ...values }, reload: Math.random() });
			setSubmitting(false);
		},
	});
	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				<Grid $columns={2}>
					<Input
						nameData="npRoutingPlanId"
						placeholderData={formatMessage({ id: 'table.npRoutingPlanId' })}
						typeData="text"
						label={formatMessage({ id: 'table.npRoutingPlanId' })}
					/>
					<Input
						nameData="operatorCode"
						placeholderData={formatMessage({ id: 'table.operatorCode' })}
						typeData="text"
						label={formatMessage({ id: 'table.operatorCode' })}
					/>
					<Input
						nameData="diallingCode"
						placeholderData={formatMessage({ id: 'table.diallingCode' })}
						typeData="text"
						label={formatMessage({ id: 'table.diallingCode' })}
					/>
					<Input
						nameData="description"
						placeholderData={formatMessage({ id: 'table.description' })}
						typeData="text"
						label={formatMessage({ id: 'table.description' })}
					/>
					<Input
						nameData="ratingCode"
						placeholderData={formatMessage({ id: 'table.ratingCode' })}
						typeData="text"
						label={formatMessage({ id: 'table.ratingCode' })}
					/>
					<Input
						nameData="customerRating"
						placeholderData={formatMessage({ id: 'table.customerRating' })}
						typeData="text"
						label={formatMessage({ id: 'table.customerRating' })}
					/>
				</Grid>

				<Flex $margin="2rem 0 0 0">
					<Submit
						onClick={formik.handleSubmit}
						disabled={formik.isSubmitting}>
						{formatMessage({ id: 'commons.search' })}
					</Submit>
				</Flex>
			</form>
		</FormikProvider>
	);
};
export default AddServerForm;
