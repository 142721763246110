import { MyButton, Tooltip } from './styles';

interface ButtonIconProps {
	$text: string;
	$icon: string;
	$position?: string;
	onClick?: any;
	$buttonType?: any;
	$tooltip?: string;
}
const ButtonIcon = (props: ButtonIconProps) => {
	const { $text, $position, $tooltip, $buttonType } = props;
	return (
		<MyButton
			{...props}
			type={$buttonType || 'button'}
			className={`${$position} ${$tooltip ? 'withTooltip' : ''}`}>
			<span>{$text}</span>
			{$tooltip && <Tooltip>{$tooltip}</Tooltip>}
		</MyButton>
	);
};
export default ButtonIcon;
