import { Routes, Route, Navigate } from 'react-router-dom';
import ScrollToTop from 'utils/scrollToUp';
import Layout from 'layout';
import Login from 'pages/Login';
import Dashboard from 'pages/Dashboard';
import Users from 'pages/users';
import AddUser from 'pages/users/add';
import EditUser from 'pages/users/edit';
import Logs from 'pages/logs';
import Portability from 'pages/portability';
import Ratings from 'pages/ratings';
import RatingLogs from 'pages/ratingLogs';
const Router = () => {
	return (
		<>
			<ScrollToTop />
			<Routes>
				<Route element={<Layout />}>
					<Route
						path="/"
						element={<Dashboard />}
					/>
					<Route
						path="users"
						element={<Users />}
					/>
					<Route
						path="users/add"
						element={<AddUser />}
					/>
					<Route
						path="users/edit/:id"
						element={<EditUser />}
					/>
					<Route
						path="logs"
						element={<Logs />}
					/>
					<Route
						path="portability"
						element={<Portability />}
					/>
					<Route
						path="ratings"
						element={<Ratings />}
					/>
					<Route
						path="ratings-logs"
						element={<RatingLogs />}
					/>
				</Route>
				<Route
					path="login"
					element={<Login />}
				/>
				<Route
					path="*"
					element={<Navigate to="/" />}
				/>
			</Routes>
		</>
	);
};
export default Router;
