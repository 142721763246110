import { Content } from 'common/containers';
import { Subtitle } from 'common/text';
import AddRatingsForm from 'components/forms/ratings/add';
import { useState } from 'react';
import { Nav, Tabs, Tab } from '../styles';
import { useIntl } from 'react-intl';
import Batch from 'components/forms/ratings/batch';
const AddRatings = () => {
	const [type, setType] = useState('single');
	const { formatMessage } = useIntl();
	return (
		<div>
			<Nav>
				<Tabs>
					<Tab
						className={type === 'single' ? 'active' : ''}
						onClick={() => setType('single')}>
						Manual
					</Tab>
					<Tab
						className={type === 'batch' ? 'active' : ''}
						onClick={() => setType('batch')}>
						{formatMessage({ id: 'blacklists.file' })}
					</Tab>
				</Tabs>
			</Nav>
			<Content $borderRadius="0 .75rem .75rem .75rem">
				{type === 'single' && (
					<div>
						<Subtitle $margin="0 0 1rem 0">Añade un rating code</Subtitle>
						<AddRatingsForm />
					</div>
				)}
				{type === 'batch' && (
					<div>
						<Subtitle $margin="0 0 1rem 0">Sube un archivo CSV para añadir un rating code</Subtitle>
						<Batch actionType="add" />
					</div>
				)}
			</Content>
		</div>
	);
};
export default AddRatings;
