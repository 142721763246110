import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useFormik, FormikProvider } from 'formik';
import { Grid, Flex } from 'common/containers';
import DropFile from 'components/forms/inputs/dropFile';
import * as Yup from 'yup';
import Submit from 'components/forms/inputs/submit';
import { SUPPORTED_FORMATS_PLAIN } from 'utils/constants';
import useHandleRatingCodes from 'hooks/useHandleBatchRatingCodes';
import { GlobalsContext } from 'context/globals';
interface ManualProps {
	actionType: string;
}
const Manual = (props: ManualProps) => {
	const { formatMessage } = useIntl();
	const { actionType } = props;
	const { dispatch } = useContext(GlobalsContext);
	const { addInBatchNumbers, deleteInBatchNumbers } = useHandleRatingCodes();
	const formik = useFormik({
		initialValues: {
			ratingCodes: '',
		},
		validationSchema: Yup.object({
			ratingCodes: Yup.mixed()
				.required('Este campo es obligatorio')
				.test('fileFormat', 'Formato no soportado', (value: any) => {
					return value && SUPPORTED_FORMATS_PLAIN.includes(value.type);
				}),
		}),
		onSubmit: async (values, { setSubmitting, resetForm }) => {
			setSubmitting(true);
			if (actionType === 'add') {
				await addInBatchNumbers(values);
				dispatch({ reload: Math.random() });
			}
			if (actionType === 'delete') {
				await deleteInBatchNumbers(values);
				dispatch({ reload: Math.random() });
			}
			resetForm();
			setSubmitting(false);
		},
	});
	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				<Grid $columns={1}>
					<DropFile name="ratingCodes" />
				</Grid>
				<Flex
					$margin={'1rem 0 0 0'}
					$h="flex-end">
					<Submit
						type="submit"
						disabled={formik.isSubmitting}>
						{formatMessage({ id: 'commons.save' })}
					</Submit>
				</Flex>
			</form>
		</FormikProvider>
	);
};
export default Manual;
